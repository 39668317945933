import React, { useEffect, useState } from 'react';
import api from '../api';
import { useParams } from 'react-router-dom';
import Loader from '../components/shared/Loader';

function Post() {

  const [post, setPost] = useState({});
  const [postLoading, setPostLoading] = useState(true);

  const { id } = useParams();

  const redirect = () => {
    window.location.replace('https://dateflix.app');
  }

  useEffect(() => {
    const fetchPost = async () => {
      if (id) {
        try {
          const result = await api.get('/articles/posts');
          setPost(result.data.results.find(p => p.id === id));
  
        } catch (err) {
          if (err) {
            redirect();
          }
        } finally {
          setPostLoading(false);
        }
      } else {
        setPostLoading(false);
      }
    }
  
    fetchPost();
  }, [id]);  

  return (
    <div className='article-page-container'>
      { postLoading ? (
        <Loader />
      ) : (
        <div className='article-article'>
          <div className='article-metadata'>
            <h1 className='article-title'>{post.title}</h1>
            <h5 className='article-date'>Posted: {post.postedDate}</h5>
          </div>
          <div className='article-body' dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
      )}
    </div>
  )
}

export default Post