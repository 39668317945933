import React, { useEffect, useState } from 'react';
import api from '../api';
import { useParams } from 'react-router-dom';
import Loader from '../components/shared/Loader';

function Guide() {

  const [guide, setGuide] = useState({});
  const [guideLoading, setGuideLoading] = useState(true);

  const { id } = useParams();

  const redirect = () => {
    window.location.replace('https://dateflix.app');
  }

  useEffect(() => {
    const fetchGuide = async () => {
      if (id) {
        try {
          const result = await api.get('/articles/guides');
          setGuide(result.data.results.find(g => g.id === id));
        } catch (err) {
          if (err) {
            redirect();
          }
        } finally {
          setGuideLoading(false);
        }
      } else {
        setGuideLoading(false);
      }
    }
  
    fetchGuide();
  }, [id]);  

  return (
    <div className='article-page-container'>
      { guideLoading ? (
        <Loader />
      ) : (
        <div className='article-article'>
          <div className='article-metadata'>
            <h1 className='article-title'>{guide.title}</h1>
            <h5 className='article-date'>Posted: {guide.postedDate}</h5>
          </div>
          <div className='article-body' dangerouslySetInnerHTML={{ __html: guide.content }} />
        </div>
      )}
    </div>
  )
}

export default Guide