import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    if (window.location.pathname.includes('/api/docs') || 
        window.location.pathname.includes('/guide') ||
        window.location.pathname.includes('/post')) {
        return null;
    }

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    const handleDownloadBtnClick = () => {
        window.open('https://apps.apple.com/gb/app/dateflix/id6740750223', '_blank');
    };
  
    const closeMenu = () => {
      setIsMenuOpen(false);
    };
  
    return (
      <div className='navigation-container'>
        <div className={`hamburger-icon ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
        </div>
        <button className='button' onClick={handleDownloadBtnClick}>Download App</button>
  
        { isMenuOpen && (
          <div className="menu-overlay" onClick={closeMenu}>
            <div className="menu-popup" onClick={(e) => e.stopPropagation()}>
              <div className="close-icon" onClick={closeMenu}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
  
              <ul className='navbar-links' onClick={closeMenu}>
                <li className='navbar-link'><Link to="/">Home</Link></li>
                <li className='navbar-link'><Link to="/about">About</Link></li>
                <li className='navbar-link'><Link to="/help">Help</Link></li>
                <li className='navbar-link'><Link to="/jobs">Jobs</Link></li>
              </ul>
            </div>
          </div>
        )}
      </div>
    );
};

export default Navbar